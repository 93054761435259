.button{
    /* width: 33.3%;
    max-width: 200px; */
    /* flex-basis: 200px */
    flex: 20%;
}
.button button{
    width: 100%;
    height: 92px;
    background: #282b33;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 40px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 30px -20px rgb(255 255 255 / 22%);
    -moz-box-shadow:0px 0px 30px -20px rgb(255 255 255 / 22%);
    box-shadow:0px 0px 30px -20px rgb(255 255 255 / 22%);
    transition: 0.3s;
}

.button button:hover{
    box-shadow: none;
}
.buttons__si button {
    color:#cf6567
} 
.cls button{
color:#07dcb4
}


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  
@media only screen and (max-width: 600px) {
    .button button{
        height: 50px;
        /* width: auto; */
        font-size: 20px;
    }
  }