#display{
    width: 100%;
    border: none;
    background: transparent;
    height: 68px;
    font-size: 65px;
    color:#fff;
    outline: none;
    
}

textarea {
    resize: none;
  }
@media only screen and (max-width: 600px) {
    #display{
        height: 40px;
        font-size: 30px;
    
    }
  }