.darkmode{
    display: flex;
    justify-content: center;
    border-radius: 16px;
    background: #2a2d37;
    width: fit-content;
    margin: 0 auto;align-items: center;
    transition: 0.3s;
    margin-bottom: 30px;
}
.darkmode img{
    border-radius: inherit;
    padding: 7px 15px;
    /* background-color: aqua; */
    cursor: pointer;
    opacity: 0.2 ;
    transition: 0.3s;
}
.darkmode img.ON{
    opacity: 1;
    transition: 0.3s;
    background-color: #353d51;
}
.darkmodeDeAct .Operations{
background: #475175;
transition: 0.3s;
}
.darkmodeDeAct .Calculator{
    background-color: #101c3c;
    transition: 0.3s;
}
.darkmodeDeAct .darkmode img.ON{
    background-color: #cf6567
}
.darkmodeDeAct{
    background-color: #f5f5f5;
    transition: 0.3s;
}
.darkmodeDeAct .button button{
    background-color: #f5f5f5;
    transition: 0.3s;
}
.darkmodeDeAct .button:not(.buttons__si,.cls) button{
    color: #101c3c;
}
.darkmodeDeAct .darkmode{
    background-color: #475175;
}

@media only screen and (max-width: 600px) {
    .darkmode{
        margin-bottom: 0px;
    }
    .darkmode img{
        padding: 3px 8px;
        max-width: 20px;
    }
  }